.tiles {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
  }
  
  .image_box {
    /* margin: 0 0 30px; */
    margin: 10px;
    position: relative;
  }
  
  .image_box>img {
    display: block;
    border: 1px solid #fd643f;
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 150px;
    margin-top: 20px;
    margin-right: 30px;
  }
  
  .image_box #gender {
    position: absolute;
    top: 30px;
    right: 40px;
  }
  
  .image_box #genderImage {
    width: 20px;
    height: 20px;
    border: 0px;
  }
  
  .img:hover {
    transform: scale(1.2);
  }
  
  .img {
    width: 80%;
    transition: transform 500ms ease;
  }
  
  .custom-btn {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #ffffff;
    padding: 5px 10px;
    border: 1px solid transparent;
    /* width: auto; */
    border-radius: 5px;
    height: 50px;
    background: #fd643f;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 0.45px;
    text-transform: uppercase;
    /* position: relative; */
    cursor: pointer;
    /* margin: 50px auto 0; */
  }
  
  .custom-btn:hover {
    background: transparent;
    border: 1px solid #60d3a0;
    color: #60d3a0;
  }
  
  .remaining_time {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /* color: #ffffff; */
    padding: 1px 5px;
    border: 1px solid transparent;
    /* width: auto; */
    width: 150px;
    border-radius: 5px;
    height: 40px;
    background: #fd643f;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 18px;
    letter-spacing: 0.85px;
    text-transform: uppercase;
    position: relative;
    cursor: default;
    margin: 15px 50px 1px 5px;
    font-weight: 800;
  }