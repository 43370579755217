.sec_detl_breeding {
  background: rgb(96 211 160 / 50%);
  border: 4px solid #f35029;
  border-radius: 15px;
  margin-bottom: 20px;
  min-height: 300px;
}

.sec_detl_breeding textarea {
  background: rgb(96 211 160 / 50%);
  min-height: 90px;
  padding: 10px;
  border: 3px solid #cccccc;
  font-family: "Poppins";
  font-size: 18px;
  color: #2a2838;
  line-height: 1.2;
  font-weight: 500;
  width: 100%;
  resize: none;
  overflow: auto;
}