.carrot_box {
  background: rgb(96 211 160 / 50%);
  border: 4px solid #f35029;
  padding: 20px;
  border-radius: 15px;
  margin: 0px;
  /* min-height: 800px; */
}

.carrot_box input {
  background: rgb(96 211 160 / 50%);
  /* min-height: 90px; */
  padding: 15px;
  border: 3px solid #cccccc;
  font-family: "Poppins";
  font-size: 18px;
  color: #2a2838;
  /* line-height: 2.2; */
  font-weight: 500;
  width: 100%;
  resize: none;
  overflow: auto;
  /* margin-bottom: 10px; */
}

.carrot_box img {
  /* background: rgb(96 211 160 / 50%); */
  /* min-height: 90px; */
  /* padding: 1px; */
  /* border: 3px solid #cccccc; */
  width: 100%;
  height: 100%;
  max-width: 50px;
  resize: none;
  overflow: auto;
}

.carrot_box textarea {
  background: rgb(96 211 160 / 50%);
  /* min-height: 70px; */
  padding: 15px;
  border: 3px solid #cccccc;
  font-family: "Poppins";
  font-size: 18px;
  color: #2a2838;
  /* line-height: 1.2; */
  font-weight: 500;
  width: 100%;
  /* resize: none; */
  /* overflow: auto; */
}

.carrot_box img {
  /* margin-top: 5px; */
  /* margin-bottom: 0px; */
}