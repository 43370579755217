.radioWallet {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* color: #ffffff; */
  /* padding: 5px 30px; */
  /* border: 1px solid transparent; */
  /* width: auto; */
  /* border-radius: 5px; */
  /* height: 50px; */
  /* background: #fd643f; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* text-decoration: none; */
  /* font-size: 18px; */
  /* letter-spacing: 0.45px; */
  /* text-transform: uppercase; */
  /* position: relative; */
  cursor: pointer;
  /* margin: 50px auto 0; */
  /* width: 100%; */
  /* height: 3em; */
}

.radioWallet:hover {
  background: transparent;
  border: 1px solid #60d3a0;
  color: #60d3a0;
}

/* HIDE RADIO */
[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type="radio"]+img {
  cursor: pointer;
  border-radius: 5px;
}

/* CHECKED STYLES */
[type="radio"]:checked+img {
  outline: 4px solid #fd643f;
}

[type="radio"]:hover+img {
  /* outline: 4px solid #60d3a0; */
  /* background: transparent; */
  /* border: 1px solid #60d3a0; */
  /* color: #60d3a0; */
}

.inputTextBox {
  padding: 4px 15px 4px 15px;
  font-weight: 500;
  width: 150px;
  height: 50px;
  max-width: 150px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  color: #282828;
  border-radius: 0px;
  font: normal normal 400 16px/25px Poppins;
  box-shadow: none;
  border: 1px solid #ebebeb;
}

.inputTextBox:hover,
.inputTextBox:focus {
  border: 1px solid #60d3a0;
}

/* bunnyDetails START */

.bunnyDetails {
  background: rgb(96 211 160 / 50%);
  border: 4px solid #f35029;
  border-radius: 15px;
  margin-bottom: 20px;
}

.bunnyDetails p {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
}

.bunnyDetails textarea {
  background: rgb(96 211 160 / 50%);
  min-height: 90px;
  padding: 10px;
  border: 3px solid #cccccc;
  font-family: "Poppins";
  font-size: 18px;
  color: #2a2838;
  line-height: 1.2;
  font-weight: 500;
  width: 100%;
  resize: none;
  overflow: auto;
}

/* bunnyDetails END */

/* bunnyDetailsImageBox START */
.bunnyDetailsImageBox {
  margin: 10px;
  position: relative;
  text-align: center;
}

.bunnyDetailsImageBox .gender {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 0px;
  margin-top: 10px;
  margin-left: -30px;
}

.bunnyDetailsImageBox .image {
  border: 1px solid #fd643f;
  border-radius: 15px;
  padding: 10px;
  width: 150px;
  height: 150px;
}

.bunnyDetailsImageBox .para {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
}

/* bunnyDetailsImageBox END */


/* image_box_top START*/
.image_box_top {
  position: relative;
  text-align: center;
}

.image_box_top .image {
  border: 1px solid #fd643f;
  border-radius: 15px;
  padding: 10px;
  width: 150px;
  height: 150px;
}

.image_box_top .gender {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 0px;
  margin-top: 10px;
  margin-left: -30px;
}

.image_box_top .para {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
}

/* image_box_top END*/